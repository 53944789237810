import * as React from "react"
import Scroll from "../components/scroll"
import Layout from "../components/layout"
import ScrollingText from "../components/scrollingText"
import HeroBanner from "../components/heroBanner"
import Listings from "../components/listings"
import AboutUs from "../components/aboutUs"
import Spacer from "../components/spacer"
import Title from "../components/title"
import News from "../components/news"
import Seo from "../components/seo"
import { graphql } from "gatsby" 


const IndexPage = ({data}) => {
  var page = data.page;
  return (
  <Layout bodyClass='homepage'>
    <Scroll />
    <HeroBanner caption={page.heroCaption} image={page.heroImage} video={page.heroVideo} mobile={page.heroMobile} /> 
    <AboutUs caption={page.about} link={page.aboutLink} image={page.aboutImage} video={page.aboutVideo}/>
    <ScrollingText text={page.announcement} />
    <Spacer />
    <Title title={page.recentTitle} link='/listings' id='latest'/>
    <Listings listings={data.listings} />
    <Spacer />
    <Title title={page.newsTitle} subtitle={page.newsSubtitle} hideButton={true}/>
    <News article={page.featuredArticle} />
    <Spacer />
  </Layout>
)
}

export const Head = () => <Seo title="Foster Burton" />

export default IndexPage

export const query = graphql`
  query Homepage {
    page:datoCmsHomepage {
      newsTitle
      newsSubtitle
      heroVideo
      heroMobile
      heroImage {
        gatsbyImageData
        format
        video {
          mp4Url
        }
      }
      about
      aboutLink
      aboutVideo
      aboutImage {
        gatsbyImageData
        format
        video {
          mp4Url
        }
      }
      recentTitle
      announcement
      featuredArticle {
        title
        link
        readTime
        featureImage {
          gatsbyImageData
        }
        meta {
          publishedAt(formatString: "D MMMM YYYY")
        }
      }
    }
    listings:allDatoCmsListing(limit: 3, sort: {fields: position, order: ASC}) {
      edges {
        node {
          title
          slug
          price
          caption
          featureImage {
            gatsbyImageData
          }
          firstImage {
            gatsbyImageData
          }
        }
      }
    }
  }
`