import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const AboutUs = ({caption, image, link, video}) => {
  return (
  <div className='about-us bg-slate mh-100h m-ha flex m-wrap'>
    <div className='w-70 m-100 white flex flex-wrap m-mt40'>
      <div className='max-900 p40'>
        <p className='h1 m-h2 m0'>{caption}</p>
      </div>
      <div className='w-100 p40 mta'>
        <Link to={link} className='button hover-white'>Read More</Link>
      </div>
    </div>
    <div className='w-30 flex m-100 m-mb20'>
      <div className='p40 mta w-100'>
        <div className='max-400 mra'>
          <div className='ratio-2-1 bg-grey pos-rel'>
            {(image ? <GatsbyImage className='bg-image' image={image?.gatsbyImageData} alt={caption} />:'')}
            {(video ?<video loop playsInline muted autoPlay className='bg-image  br-08 bg-video' allowFullScreen src={video} />:'')}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AboutUs
