import * as React from "react"

const ScrollingText = ({text}) => (
  <div className='pt10 pb10 bb1 strong uppercase small max-100'>
    <div className='overflow-hidden'>
      <div className='inner ticker'>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
        <span>{text}</span>
      </div>
    </div>
  </div>
)

export default ScrollingText
