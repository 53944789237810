import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroBanner = ({ caption, image, video, mobile }) => { 
  return (
    <div className='hero--banner bg-black flex'>
      {(image ? <GatsbyImage className='bg-image' image={image?.gatsbyImageData} alt={caption} />:'')}
      {(video ?<video loop playsInline muted autoPlay className='bg-image m-hide  br-08 bg-video' allowFullScreen src={video} />:'')}
      {(mobile ?<video loop playsInline muted autoPlay className='bg-image m-show  br-08 bg-video' allowFullScreen src={mobile} />:'')}
    </div>
  )
}

export default HeroBanner
